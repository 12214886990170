import { WhatsappContactListItem, WhatsappInstanceStateEnum } from '@api-clients/crm-api-client';
import { Chat, SelectedChat } from './whatsapp-contacts.interfaces';

const SelectedChatVoid: SelectedChat = {
  crmCardId: null,
  phone: null,
  contactId: null,
};

export interface WhatsappContactsState {
  contactList: WhatsappContactListItem[];
  chats: Chat[];
  unreadChatCount: number;
  selectedChat: SelectedChat;
  isLoaded: boolean;
  error: boolean;
  whatsappState: WhatsappInstanceStateEnum;
  qrLink?: string;
}

export const whatsappContactsInitialState: WhatsappContactsState = {
  contactList: [],
  chats: [],
  unreadChatCount: 0,
  selectedChat: SelectedChatVoid,
  isLoaded: false,
  error: false,
  whatsappState: null,
  qrLink: null,
};

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY } from './whatsapp-contacts.reducers';
import { WhatsappContactsState } from './whatsapp-contacts.state';

export const getWhatsappContactsFeature = createFeatureSelector<WhatsappContactsState>(USER_FEATURE_KEY);

export const getWhatsappContacts = createSelector(
  getWhatsappContactsFeature,
  (payload: WhatsappContactsState) => payload.contactList,
);

export const getWhatsappUnreadChats = createSelector(
  getWhatsappContactsFeature,
  (payload: WhatsappContactsState) => payload.unreadChatCount,
);
export const getWhatsappState = createSelector(
  getWhatsappContactsFeature,
  (payload: WhatsappContactsState) => payload.whatsappState,
);

export const getSelectedChat = createSelector(
  getWhatsappContactsFeature,
  (payload: WhatsappContactsState) => payload.selectedChat,
);

export const getChats = createSelector(
  getWhatsappContactsFeature,
  (payload: WhatsappContactsState) => payload.chats,
);

export const getQrLink = createSelector(
  getWhatsappContactsFeature,
  (payload: WhatsappContactsState) => payload.qrLink,
);

import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CrmCardListItem,
  CrmCardListResponse,
  UpdateLastTouchDateRequest,
} from '@api-clients/crm-api-client/dist/models';
import { Subject, timer } from 'rxjs';
import { delay, retryWhen, share, switchMap, takeUntil } from 'rxjs/operators';
import { CrmCardListPostSaleResponse, CrmCardListPostSaleTourPackage } from '@api-clients/crm-api-client';
import { apiResponsePipe } from '../../../../../api-response.pipe';
import { AppConfig } from '../../../../../../environments/environment';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';

const REFRESH_INTERVAL = 120000;

@Injectable({
  providedIn: 'root',
})
export class DealListService implements OnDestroy {
  private stopPollingSubject = new Subject<void>();

  constructor(private http: HttpClient) {}

  getDealList() {
    return timer(1, REFRESH_INTERVAL).pipe(
      switchMap(() =>
        this.http
          .get<ApiResponse<CrmCardListResponse>>(`${AppConfig.apiUrl}/deal/list`)
          .pipe(apiResponsePipe),
      ),
      retryWhen(errors => errors.pipe(delay(REFRESH_INTERVAL))),
      share(),
      takeUntil(this.stopPollingSubject),
    );
  }

  updateLastTouchDateRequest(dealId: number) {
    const body: UpdateLastTouchDateRequest = {
      dealId,
    };
    return this.http.post(`${AppConfig.apiUrl}/deal/update-last-touch-date`, body).pipe(apiResponsePipe);
  }

  getDealListPostSale() {
    return timer(1, REFRESH_INTERVAL).pipe(
      switchMap(() =>
        this.http
          .get<ApiResponse<CrmCardListPostSaleResponse>>(`${AppConfig.apiUrl}/deal/list-post-sale`)
          .pipe(apiResponsePipe),
      ),
      retryWhen(errors => errors.pipe(delay(REFRESH_INTERVAL))),
      share(),
      takeUntil(this.stopPollingSubject),
    );
  }

  ngOnDestroy() {
    this.stopPollingSubject.next();
  }

  public isTodayDeal(crmCardListItem: CrmCardListItem): boolean {
    const today = new Date();
    return !crmCardListItem.nextTask || !(today <= new Date(crmCardListItem.nextTask?.date));
  }

  public isTouchedTodayDeal(crmCardListItem: CrmCardListItem): boolean {
    return crmCardListItem.experimentalData?.isContactedToday;
  }

  public isTodayDealPostSale(crmCardListPostSaleTourPackage: CrmCardListPostSaleTourPackage): boolean {
    const today = new Date();
    return (
      !crmCardListPostSaleTourPackage.relevanceDate ||
      !(today <= new Date(crmCardListPostSaleTourPackage.relevanceDate))
    );
  }
}
